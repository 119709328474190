import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import {
  CREATE_BID_REQ_AIR,
  CREATE_BID_REQ_DESTINATION,
  CREATE_BID_REQ_LCL,
  CREATE_BID_REQ_ORIGIN,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM } from "@sellernote/_shared/src/api-interfaces/shipda-api/integratedRealtimeService";
import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_CREATE_QUERY";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { FreightType, Port } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateFormData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import {
  calculateCBM,
  calculateCW,
  calculateRTon,
  toFixedFloat,
  toKg,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import {
  getCountryOfPort,
  getPortId,
} from "@sellernote/_shared/src/utils/forwarding/adminBidCreate";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import { getContainersInfoPayload } from "../utils";

export default function useCreateExportButton({
  portList,
  formData,
  freightType,
  checkBlankInBidCreateForm,
  sessionStorageShipmentCreateInfo,
  sessionStorageShipmentCreateUserInfo,
}: {
  portList: Port[];
  formData: BidCreateFormData;
  freightType: FreightType;
  checkBlankInBidCreateForm: (formData: BidCreateFormData) => boolean;

  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
}) {
  const history = useHistory();

  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { mutate: createExportBid, isLoading: createdExportBidLoading } =
    ADMIN_BID_CREATE_QUERY.useCreateExportBid();

  const {
    UploadResponseSnackBar: ResponseUploadSnackBar,
    setShowsErrorSnackBar,
    setErrorMessage,
  } = useUploadResponseSnackBar();

  const getRequestOriginData = useCallback(() => {
    const origin: CREATE_BID_REQ_ORIGIN = {
      startCountry:
        formData.startType !== "inland"
          ? getCountryOfPort(portList, formData.startPort)
          : getCountryOfPort(portList, formData.startViaPort),
      startPortId:
        formData.startType !== "inland"
          ? getPortId(portList, formData.startPort)
          : undefined,
      startViaPortId:
        formData.startType === "inland"
          ? getPortId(portList, formData.startViaPort)
          : undefined,
      startAddress: formData.startAddress ?? undefined,
      startAddressDetail: formData.startAddressDetail ?? undefined,
      startType: formData.startType,
      zoneId: formData.startType === "inland" ? formData.zoneId : undefined,
    };

    return origin;
  }, [
    formData.startAddress,
    formData.startAddressDetail,
    formData.startPort,
    formData.startType,
    formData.startViaPort,
    formData.zoneId,
    portList,
  ]);

  const getRequestDestinationData = useCallback(() => {
    const origin: CREATE_BID_REQ_DESTINATION = {
      endCountry:
        formData.endType !== "inland"
          ? getCountryOfPort(portList, formData.endPort)
          : getCountryOfPort(portList, formData.endViaPort),
      endPortId:
        formData.endType !== "inland"
          ? getPortId(portList, formData.endPort)
          : undefined,
      endViaPortId:
        formData.endType === "inland"
          ? getPortId(portList, formData.endViaPort)
          : undefined,
      endAddress: formData.endAddress ?? undefined,
      endType: formData.endType ?? undefined,
    };

    return origin;
  }, [
    formData.endAddress,
    formData.endPort,
    formData.endType,
    formData.endViaPort,
    portList,
  ]);

  const getUnitSupply = useCallback(
    (productsItem: SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM) => {
      const calculatedCBM =
        calculateCBM({
          type: "lcl",
          width: productsItem.horizontal || 0,
          height: productsItem.height || 0,
          depth: productsItem.vertical || 0,
          sizeUnit: productsItem.volumeUnit,
        }) || 0;

      const cbm = toFixedFloat(calculatedCBM * productsItem.quantity, 2) ?? 0;

      if (freightType === "LCL") {
        if (cargoInfoFormType === "totalVolume") {
          return calculateRTon(
            productsItem.cbm,
            toTon(productsItem.weight, productsItem.weightUnit)
          );
        }
        return calculateRTon(
          cbm ?? 0,
          toTon(productsItem.weight, productsItem.weightUnit)
        );
      }

      if (cargoInfoFormType === "totalVolume" && productsItem) {
        return (
          toFixedFloat(
            calculateCW({
              type: "cbm",
              cbm: productsItem.cbm,
              weight: toKg(productsItem.weight, productsItem.weightUnit),
            }),
            2
          ) ?? 0
        );
      }
      return (
        toFixedFloat(
          calculateCW({
            type: "cbm",
            cbm: cbm ?? 0,
            weight: toTon(productsItem.weight ?? 0, productsItem.weightUnit),
          }),
          2
        ) ?? 0
      );
    },
    [cargoInfoFormType, freightType]
  );

  const getProductsInfoWithUnitSupply = useCallback(() => {
    return formData.productsInfo?.map((productsItem) => {
      if (cargoInfoFormType === "packaging") {
        return {
          ...productsItem,
          unitSupply: getUnitSupply(productsItem),
          cbm:
            toFixedFloat(
              calculateCBM({
                type: "lcl",
                width: productsItem.horizontal || 0,
                height: productsItem.height || 0,
                depth: productsItem.vertical || 0,
                sizeUnit: productsItem.volumeUnit,
              }) ?? 0 * productsItem.quantity,
              2
            ) ?? 0,
        };
      }
      return {
        ...productsItem,
        // packaging에서는 필요없는 정보
        volumeUnit: undefined,
        height: undefined,
        vertical: undefined,
        horizontal: undefined,
        unitSupply: getUnitSupply(productsItem),
      };
    });
  }, [cargoInfoFormType, formData.productsInfo, getUnitSupply]);

  const getRequestLclData = useCallback(() => {
    const lcl: CREATE_BID_REQ_LCL = {
      supply: formData.supply,

      totalCBM:
        cargoInfoFormType === "totalVolume" ? undefined : formData.totalCBM,
      totalWeight:
        cargoInfoFormType === "totalVolume" ? undefined : formData.totalWeight,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return lcl;
  }, [
    cargoInfoFormType,
    formData.supply,
    formData.totalCBM,
    formData.totalWeight,
    getProductsInfoWithUnitSupply,
  ]);

  const getRequestAirData = useCallback(() => {
    const air: CREATE_BID_REQ_AIR = {
      supply: formData.supply,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return air;
  }, [formData.supply, getProductsInfoWithUnitSupply]);

  const handleExportBidCreate = useCallback(() => {
    // 도착지 셀렉트에서 입력만 하고 선택하지 않았을 때
    if (!formData.zoneId && formData.startAddress) {
      setErrorMessage("출발지 주소가 선택되지 않았습니다. 확인해주세요");
      setShowsErrorSnackBar(true);

      return;
    }

    createExportBid(
      {
        pathParams: {
          userId: sessionStorageShipmentCreateUserInfo.userId,
          teamId: sessionStorageShipmentCreateUserInfo.teamId,
        },
        origin: getRequestOriginData(),
        destination: getRequestDestinationData(),
        fcl:
          formData.freightType === "FCL"
            ? {
                containersInfo: getContainersInfoPayload({
                  containersInfo: formData.containersInfo,
                  isOpenApiAuth: sessionStorageShipmentCreateInfo.isOpenApiAuth,
                }),
              }
            : undefined,
        lcl: formData.freightType === "LCL" ? getRequestLclData() : undefined,
        air: formData.freightType === "AIR" ? getRequestAirData() : undefined,
        freightType: formData.freightType,
        transportType: formData.freightType === "AIR" ? "air" : "sea",
        /** 현재는 1로 고정 */
        importerCount: 1,
        // 요청 시 값이 없으면 false로 보내야함
        additional: {
          importCustoms: formData.importCustoms ?? false,
          useVGM: formData.useVGM ?? false,
          exportCustoms: formData.exportCustoms ?? false,
          hopeCargoInsurance: formData.hopeCargoInsurance ?? false,
        },
        checkpoint: {
          cfsReceiving: formData.cfsReceiving,
          // FCL이고 D조건일때만 요청 아니면 해당 없음 해당없음은 false보낸다.
          containerDevanning:
            freightType === "FCL"
              ? formData.containerDevanning === "TRUE"
              : false,
          inlandTransportType: formData.inlandTransportType,
          // FCL일때만 사용 그 외는 해당 없음 해당없음은 false보낸다.
          containerStuffing:
            freightType === "FCL"
              ? formData.containerStuffing === "TRUE"
              : false,
        },
        incoterms: formData.incoterms,
        isFixed: false,
      },
      {
        onSuccess: (response) => {
          /** 수출에서는 발주 > 의뢰 생성이 없기 때문에 생성 후 바로 의뢰상세로 이동 */
          history.replace(`/bid/detail/${response.data.id}`);
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  }, [
    formData.zoneId,
    formData.startAddress,
    formData.freightType,
    formData.containersInfo,
    formData.importCustoms,
    formData.useVGM,
    formData.exportCustoms,
    formData.hopeCargoInsurance,
    formData.cfsReceiving,
    formData.containerDevanning,
    formData.inlandTransportType,
    formData.containerStuffing,
    formData.incoterms,
    createExportBid,
    sessionStorageShipmentCreateUserInfo.userId,
    sessionStorageShipmentCreateUserInfo.teamId,
    getRequestOriginData,
    getRequestDestinationData,
    sessionStorageShipmentCreateInfo.isOpenApiAuth,
    getRequestLclData,
    getRequestAirData,
    freightType,
    setErrorMessage,
    setShowsErrorSnackBar,
    history,
  ]);

  const CreateExportBidButton = useMemo(() => {
    return (
      <Grid item>
        <Button
          disabled={
            checkBlankInBidCreateForm(formData) || createdExportBidLoading
          }
          type="submit"
          variant="contained"
        >
          {createdExportBidLoading ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            "수출 의뢰 생성"
          )}
        </Button>

        {ResponseUploadSnackBar}
      </Grid>
    );
  }, [
    ResponseUploadSnackBar,
    checkBlankInBidCreateForm,
    createdExportBidLoading,
    formData,
  ]);

  return { CreateExportBidButton, handleExportBidCreate };
}
