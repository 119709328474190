import { useParams } from "react-router-dom";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import ConfirmQuotation from "./ConfirmQuotation";

const ConfirmQuotationDetail = () => {
  const { id }: { id: string } = useParams();

  const { data: bidDetail, isFetching: isBidDetailFetching } =
    ADMIN_BID_QUERY.useGetAdminBidDetail({
      bidId: Number(id),
    });

  const { data: exchangeRateList } = COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: bidDetail?.locale,
  });

  const { data: applyItems } = ADMIN_BID_QUERY.useGetAdminBidApplyItems({});

  const { data: linerList } = COMMON_QUERY.useGetLinerList();

  if (
    !bidDetail ||
    !exchangeRateList ||
    !applyItems ||
    !linerList ||
    isBidDetailFetching
  ) {
    return (
      <Layout breadcrumbs={["입찰 관리"]} title={"확정 견적 선정"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["입찰 관리"]} title={"확정 견적 선정"}>
      <ConfirmQuotation
        bidDetail={bidDetail}
        exchangeRateList={exchangeRateList}
        applyItems={applyItems}
        linerList={linerList}
      />
    </Layout>
  );
};

export default withRequireAuth(ConfirmQuotationDetail);
