import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import {
  CHECK_IS_OCEANTICKET_RES,
  CREATE_BID_REQ_AIR,
  CREATE_BID_REQ_DESTINATION,
  CREATE_BID_REQ_FCL,
  CREATE_BID_REQ_LCL,
  CREATE_BID_REQ_ORIGIN,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM } from "@sellernote/_shared/src/api-interfaces/shipda-api/integratedRealtimeService";
import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_CREATE_QUERY";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { FreightType, Port } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateFormData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import {
  calculateCBM,
  calculateCW,
  calculateRTon,
  toFixedFloat,
  toKg,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import {
  getCountryOfPort,
  getPortId,
  handleShipmentCreateSuccess,
} from "@sellernote/_shared/src/utils/forwarding/adminBidCreate";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import { getContainersInfoPayload } from "../utils";

export default function useBidCreateButton({
  portList,
  formData,
  isOceanticket,
  freightType,
  checkBlankInBidCreateForm,
  hasPO,
  POId,
  formIndex,
  handleSessionStorageShipmentCreateInfoChange,
  sessionStorageShipmentCreateInfo,
  sessionStorageShipmentCreateUserInfo,
}: {
  portList: Port[];
  formData: BidCreateFormData;
  isOceanticket: CHECK_IS_OCEANTICKET_RES | undefined;
  freightType: FreightType;
  checkBlankInBidCreateForm: (formData: BidCreateFormData) => boolean;
  hasPO?: boolean;
  POId?: number;
  formIndex: number;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
}) {
  const history = useHistory();

  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { mutate: createBid, isLoading: createdBidLoading } =
    ADMIN_BID_CREATE_QUERY.useCreateBid();

  const {
    UploadResponseSnackBar: ResponseUploadSnackBar,
    setShowsErrorSnackBar,
    setErrorMessage,
  } = useUploadResponseSnackBar();

  const getRequestOriginData = useCallback(() => {
    const origin: CREATE_BID_REQ_ORIGIN = {
      startCountry:
        formData.startType !== "inland"
          ? getCountryOfPort(portList, formData.startPort)
          : getCountryOfPort(portList, formData.startViaPort),
      startPortId:
        formData.startType !== "inland"
          ? getPortId(portList, formData.startPort)
          : undefined,
      startViaPortId:
        formData.startType === "inland"
          ? getPortId(portList, formData.startViaPort)
          : undefined,
      startAddress: formData.startAddress ?? undefined,
      startType: formData.startType ?? undefined,
    };

    return origin;
  }, [
    formData.startAddress,
    formData.startPort,
    formData.startType,
    formData.startViaPort,
    portList,
  ]);

  const getRequestDestinationData = useCallback(() => {
    const origin: CREATE_BID_REQ_DESTINATION = {
      endCountry:
        formData.endType !== "inland"
          ? getCountryOfPort(portList, formData.endPort)
          : getCountryOfPort(portList, formData.endViaPort),
      endPortId:
        formData.endType !== "inland"
          ? getPortId(portList, formData.endPort)
          : undefined,
      endViaPortId:
        formData.endType === "inland"
          ? getPortId(portList, formData.endViaPort)
          : undefined,
      endAddress: formData.endAddress ?? undefined,
      endType: formData.endType,
      endAddressDetail: formData.endAddressDetail ?? undefined,
      zoneId: formData.endType === "inland" ? formData.zoneId : undefined,
    };

    return origin;
  }, [
    formData.endAddress,
    formData.endAddressDetail,
    formData.endPort,
    formData.endType,
    formData.endViaPort,
    formData.zoneId,
    portList,
  ]);

  /**
   * 발주물품 정보를 물품정보 폼에서 추출해주는 함수
   */
  const getPOProducts = useCallback(() => {
    if (
      freightType === "FCL" &&
      sessionStorageShipmentCreateInfo.isOpenApiAuth
    ) {
      const productsArray = formData.containersInfo?.map(
        ({ products, ...containerItem }) => {
          return products;
        }
      );

      return productsArray?.flat() as
        | {
            productName: string;
            separator?: string;
          }[]
        | undefined;
    }

    if (sessionStorageShipmentCreateInfo.isOpenApiAuth) {
      return formData.products;
    }
  }, [
    formData.containersInfo,
    formData.products,
    freightType,
    sessionStorageShipmentCreateInfo.isOpenApiAuth,
  ]);

  const getRequestFclData = useCallback(() => {
    const fcl: CREATE_BID_REQ_FCL = {
      containerAccessable:
        formData.endType === "inland"
          ? formData.containerAccessable === "TRUE"
          : undefined,
      containOceanSurcharge: formData.containOceanSurcharge
        ? formData.containOceanSurcharge === "TRUE"
        : undefined,
      containLss: formData.containLss
        ? formData.containLss === "TRUE"
        : undefined,
      containersInfo: getContainersInfoPayload({
        containersInfo: formData.containersInfo,
        isOpenApiAuth: sessionStorageShipmentCreateInfo.isOpenApiAuth,
      }),
    };

    return fcl;
  }, [
    formData.containLss,
    formData.containOceanSurcharge,
    formData.containerAccessable,
    formData.containersInfo,
    formData.endType,
    sessionStorageShipmentCreateInfo.isOpenApiAuth,
  ]);

  const getUnitSupply = useCallback(
    (productsItem: SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM) => {
      const calculatedCBM =
        calculateCBM({
          type: "lcl",
          width: productsItem.horizontal || 0,
          height: productsItem.height || 0,
          depth: productsItem.vertical || 0,
          sizeUnit: productsItem.volumeUnit,
        }) || 0;

      const cbm = toFixedFloat(calculatedCBM * productsItem.quantity, 2) ?? 0;

      if (freightType === "LCL") {
        if (cargoInfoFormType === "totalVolume") {
          return calculateRTon(
            productsItem.cbm,
            toTon(productsItem.weight, productsItem.weightUnit)
          );
        }
        return calculateRTon(
          cbm ?? 0,
          toTon(productsItem.weight, productsItem.weightUnit)
        );
      }

      if (cargoInfoFormType === "totalVolume" && productsItem) {
        return (
          toFixedFloat(
            calculateCW({
              type: "cbm",
              cbm: productsItem.cbm,
              weight: toKg(productsItem.weight, productsItem.weightUnit),
            }),
            2
          ) ?? 0
        );
      }
      return (
        toFixedFloat(
          calculateCW({
            type: "cbm",
            cbm: cbm ?? 0,
            weight: toTon(productsItem.weight ?? 0, productsItem.weightUnit),
          }),
          2
        ) ?? 0
      );
    },
    [cargoInfoFormType, freightType]
  );

  const getProductsInfoWithUnitSupply = useCallback(() => {
    return formData.productsInfo?.map((productsItem) => {
      if (cargoInfoFormType === "packaging") {
        return {
          ...productsItem,
          unitSupply: getUnitSupply(productsItem),
          cbm:
            toFixedFloat(
              calculateCBM({
                type: "lcl",
                width: productsItem.horizontal || 0,
                height: productsItem.height || 0,
                depth: productsItem.vertical || 0,
                sizeUnit: productsItem.volumeUnit,
              }) ?? 0 * productsItem.quantity,
              2
            ) ?? 0,
        };
      }
      return {
        ...productsItem,
        // packaging에서는 필요없는 정보
        volumeUnit: undefined,
        height: undefined,
        vertical: undefined,
        horizontal: undefined,
        unitSupply: getUnitSupply(productsItem),
      };
    });
  }, [cargoInfoFormType, formData.productsInfo, getUnitSupply]);

  const getRequestLclData = useCallback(() => {
    const lcl: CREATE_BID_REQ_LCL = {
      supply: formData.supply,
      containOceanSurcharge: formData.containOceanSurcharge
        ? formData.containOceanSurcharge === "TRUE"
        : undefined,
      containLss: formData.containLss
        ? formData.containLss === "TRUE"
        : undefined,
      totalCBM:
        cargoInfoFormType === "totalVolume" ? undefined : formData.totalCBM,
      totalWeight:
        cargoInfoFormType === "totalVolume" ? undefined : formData.totalWeight,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return lcl;
  }, [
    cargoInfoFormType,
    formData.containLss,
    formData.containOceanSurcharge,
    formData.supply,
    formData.totalCBM,
    formData.totalWeight,
    getProductsInfoWithUnitSupply,
  ]);

  const getRequestAirData = useCallback(() => {
    const air: CREATE_BID_REQ_AIR = {
      supply: formData.supply,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return air;
  }, [formData.supply, getProductsInfoWithUnitSupply]);

  const handleBidCreate = useCallback(() => {
    // 도착지 셀렉트에서 입력만 하고 선택하지 않았을 때
    if (!formData.zoneId && formData.endAddress) {
      setErrorMessage("도착지 주소가 선택되지 않았습니다. 확인해주세요");
      setShowsErrorSnackBar(true);

      return;
    }

    createBid(
      {
        pathParams: {
          userId: sessionStorageShipmentCreateUserInfo.userId,
          teamId: sessionStorageShipmentCreateUserInfo.teamId,
        },
        origin: getRequestOriginData(),
        destination: getRequestDestinationData(),
        fcl: formData.freightType === "FCL" ? getRequestFclData() : undefined,
        lcl: formData.freightType === "LCL" ? getRequestLclData() : undefined,
        air: formData.freightType === "AIR" ? getRequestAirData() : undefined,
        freightType: formData.freightType,
        serviceType: isOceanticket ? "oceanTicket" : "general",
        transportType: formData.freightType === "AIR" ? "air" : "sea",
        /** 현재는 1로 고정 */
        exportersCount: 1,

        incoterms: formData.incoterms,
        inlandType:
          formData.endType === "inland" ? formData.inlandType : undefined,
        containCustoms: formData.containCustoms,
        containDomesticFee: formData.containDomesticFee
          ? formData.containDomesticFee === "TRUE"
          : false,
        hopeCargoInsurance: formData.hopeCargoInsurance,
        freeStorageDays:
          isOceanticket && formData.containsWarehouseFee === "TRUE"
            ? 7
            : undefined,
        needFTACertificateAgency: formData.needFTACertificateAgency,
        needIORAgency: formData.needIORAgency,
        isFixed: false,
        hasPo: hasPO,
        poId: POId,
        products: getPOProducts(),
        invoicePrice: sessionStorageShipmentCreateInfo.isOpenApiAuth
          ? formData.invoicePrice
          : undefined,
      },
      {
        onSuccess: (response) => {
          const handleMoveToOrderPage = () => {
            history.push(`/order/${sessionStorageShipmentCreateInfo.POId}`);
          };

          const handleMoveToShipmentDetailPage = () => {
            history.replace(`/bid/detail/${response.data.id}`);
          };

          handleShipmentCreateSuccess({
            hasPO,
            sessionStorageShipmentCreateInfo: sessionStorageShipmentCreateInfo,
            formIndex,
            formData,
            handleMoveToOrderPage,
            handleMoveToShipmentDetailPage,
            handleSessionStorageShipmentCreateInfoChange,
          });
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  }, [
    formData,
    createBid,
    sessionStorageShipmentCreateUserInfo.userId,
    sessionStorageShipmentCreateUserInfo.teamId,
    getRequestOriginData,
    getRequestDestinationData,
    getRequestFclData,
    getRequestLclData,
    getRequestAirData,
    isOceanticket,
    hasPO,
    POId,
    getPOProducts,
    sessionStorageShipmentCreateInfo,
    setErrorMessage,
    setShowsErrorSnackBar,
    formIndex,
    handleSessionStorageShipmentCreateInfoChange,
    history,
  ]);

  const CreateBidButton = useMemo(() => {
    return (
      <Grid item>
        <Button
          disabled={checkBlankInBidCreateForm(formData) || createdBidLoading}
          type="submit"
          variant="contained"
        >
          {createdBidLoading ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            "의뢰 생성"
          )}
        </Button>

        {ResponseUploadSnackBar}
      </Grid>
    );
  }, [
    ResponseUploadSnackBar,
    checkBlankInBidCreateForm,
    createdBidLoading,
    formData,
  ]);

  return { CreateBidButton, handleBidCreate };
}
