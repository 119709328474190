import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Checkbox } from "@mui/material";

import useSet from "@sellernote/_shared/src/hooks/common/useSet";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";
import {
  PartnerBusinessArea,
  PartnerListItem,
  PartnerManagerItem,
} from "@sellernote/_shared/src/types/forwarding/partner";
import { getMainManagerInfo } from "@sellernote/_shared/src/utils/forwarding/partner";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";
import PartnerManagersListModal from "@sellernote/_shared-for-forwarding-admin/src/containers/PartnerManagersListModal";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetObjectWithTermSearchTypeKey";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";

import { renderCountryInCharge } from "pages/partner/utils";
import { useBidPartnerContext } from "../../hooks/useBidPartnerContext";

type CellId =
  | keyof PartnerListItem
  | "mainManagerName"
  | "mainManagerPosition"
  | "mainManagerPhone"
  | "mainManagerEmail"
  | "showsManagersModal"
  | "checkbox";

const termSearchTypeOptions: TermSearchType<"companyName">[] = [
  {
    label: "회사명",
    value: "companyName",
  },
];

function BidPartnerListModal({
  showsBidPartnerModal,
  setShowsBidPartnerModal,
  transportMode,
  region,
}: {
  showsBidPartnerModal: boolean;
  setShowsBidPartnerModal: React.Dispatch<React.SetStateAction<boolean>>;
  transportMode: "FCL" | "LCL" | "AIR" | "EXPRESS";
  region: SellernoteAppRegion;
}) {
  const { partnerBusinessArea, bidAccountPayableId, bidDetail } =
    useBidPartnerContext();

  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [showsPartnerManagersListModal, setShowsPartnerManagersListModal] =
    useState(false);
  const [managers, setManagers] = useState<PartnerManagerItem[]>([]);

  const { mutate: updateAccountPayable } =
    TRELLO_BID_QUERY.useUpdateAccountPayable(bidDetail.id);

  const { mutate: updateBidPartner } = ADMIN_BID_QUERY.useUpdateBidPartner(
    bidDetail.id
  );

  const {
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setErrorMessage,
    setShowsErrorSnackBar,
  } = useUploadResponseSnackBar();

  const { set: checkBoxSet, init: initCheckBoxSet } = useSet<number>();

  const {
    array: managerCheckBoxArr,
    set: managerCheckBoxSet,
    toggle: toggleManagerCheckBox,
    init: initManagerCheckBoxSet,
  } = useSet<number>();

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const { data: countryData = [] } = COMMON_QUERY.useGetCountryList();

  const handleBidPartnerUpdate = useCallback(
    ({
      partnerBusinessArea,
      bidAccountPayableId,
      partnerManagersIds,
    }: {
      partnerBusinessArea: PartnerBusinessArea;
      bidAccountPayableId: number;
      partnerManagersIds: number[];
    }) => {
      updateBidPartner(
        {
          businessArea: partnerBusinessArea,
          bidAccountPayableId,
          partnerManagersIds,
        },
        {
          onSuccess: () => {
            setShowsSuccessSnackBar(true);
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: bidDetail.id,
              })
            );
          },

          onError: ({ response }) => {
            if (response?.data?.code === 400) {
              setErrorMessage(
                bidDetail.isImport
                  ? "수출자 정보를 먼저 입력해야 합니다."
                  : "수입자 정보를 먼저 입력해야 합니다."
              );
              setShowsErrorSnackBar(true);
              return;
            } else {
              setShowsErrorSnackBar(true);
            }
          },
        }
      );
    },
    [
      bidDetail.id,
      bidDetail.isImport,
      queryClient,
      setErrorMessage,
      setShowsErrorSnackBar,
      setShowsSuccessSnackBar,
      updateBidPartner,
    ]
  );

  const handleAccountPayableUpdate = useCallback(() => {
    if (bidAccountPayableId) {
      handleBidPartnerUpdate({
        partnerBusinessArea,
        bidAccountPayableId,
        partnerManagersIds: managerCheckBoxArr,
      });
    } else {
      updateAccountPayable(
        { domain: partnerBusinessArea },
        {
          onSuccess: ({ data }) => {
            handleBidPartnerUpdate({
              partnerBusinessArea,
              bidAccountPayableId: data.bidAccountPayableId,
              partnerManagersIds: managerCheckBoxArr,
            });
          },

          onError: ({ response }) => {
            setErrorMessage("AccountPayable 생성에 실패했습니다.");
            setShowsErrorSnackBar(true);
          },
        }
      );
    }
  }, [
    bidAccountPayableId,
    partnerBusinessArea,
    handleBidPartnerUpdate,
    managerCheckBoxArr,
    setErrorMessage,
    setShowsErrorSnackBar,
    updateAccountPayable,
  ]);

  const { data: partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: currentPage,
    perPage,
    transportMode,
    businessArea: partnerBusinessArea,
    ...objectWithTermSearchTypeKey,
    enabled: true,
    region,
  });

  const handlePartnerManagersListModalOpen = useCallback(
    (managers: PartnerManagerItem[]) => {
      return () => {
        setManagers(managers);
        setShowsPartnerManagersListModal(true);
      };
    },
    []
  );

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      { id: "checkbox", disablePadding: false, label: "선택" },
      { id: "id", disablePadding: false, label: "ID" },
      { id: "name", disablePadding: false, label: "회사명" },
      { id: "language", disablePadding: false, label: "언어" },
      {
        id: "countries",
        disablePadding: false,
        label: "담당 국가",
        width: 140,
      },
      { id: "mainManagerName", disablePadding: false, label: "담당자 이름" },
      { id: "mainManagerPosition", disablePadding: false, label: "직함" },
      { id: "mainManagerPhone", disablePadding: false, label: "전화번호" },
      { id: "mainManagerEmail", disablePadding: false, label: "이메일" },
      { id: "showsManagersModal", disablePadding: false, label: "매니저" },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!partnerList?.list) return [];

    return partnerList.list.map((partnerListItem) => {
      const row: TableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(partnerListItem.id)}
            onClick={(e) => {
              e.stopPropagation();
              initCheckBoxSet([partnerListItem.id]);
              const idsToCheck = partnerListItem.managers.map(
                (managersItem) => {
                  return managersItem.id;
                }
              );
              initManagerCheckBoxSet(idsToCheck);
            }}
          />
        ),

        id: partnerListItem.id,
        name: partnerListItem.name,
        language: partnerListItem.language,
        countries: renderCountryInCharge({
          countryDataList: countryData,
          countryInChargeOfPartner: partnerListItem.countries,
        }),
        mainManagerName: getMainManagerInfo("name", partnerListItem.managers),
        mainManagerPosition: getMainManagerInfo(
          "position",
          partnerListItem.managers
        ),
        mainManagerPhone: getMainManagerInfo("phone", partnerListItem.managers),
        mainManagerEmail: getMainManagerInfo("email", partnerListItem.managers),
        showsManagersModal: (
          <Button
            onClick={handlePartnerManagersListModalOpen(
              partnerListItem.managers
            )}
          >
            보기
          </Button>
        ),
      };
      return row;
    });
  }, [
    checkBoxSet,
    countryData,
    handlePartnerManagersListModalOpen,
    initCheckBoxSet,
    initManagerCheckBoxSet,
    partnerList?.list,
  ]);

  const handleModalClose = useCallback(() => {
    setShowsBidPartnerModal(false);
  }, [setShowsBidPartnerModal]);

  return (
    <Modal
      isOpened={showsBidPartnerModal}
      handleClose={handleModalClose}
      modalBody={
        <>
          <Table
            toolbarItems={{
              left: [
                TermSearchPanel,
                <Button
                  key="request-button"
                  variant="contained"
                  onClick={handleAccountPayableUpdate}
                >
                  파트너 선택
                </Button>,
              ],
            }}
            sx={{ height: "500px", width: "1400px" }}
            title="의뢰 파트너 리스트"
            headCells={headCells}
            rows={rows}
            pagination={{
              totalCount: partnerList?.total || 0,
              perPage,
              setPerPage,
              currentPage,
              setCurrentPage,
            }}
          />

          {showsPartnerManagersListModal && (
            <PartnerManagersListModal
              showsPartnerManagersListModal={showsPartnerManagersListModal}
              setShowsPartnerManagersListModal={
                setShowsPartnerManagersListModal
              }
              managers={managers}
              partnerCheckboxSet={checkBoxSet}
              managerCheckBoxSet={managerCheckBoxSet}
              toggleManagerCheckBox={toggleManagerCheckBox}
              initManagerCheckBoxSet={initManagerCheckBoxSet}
            />
          )}

          {UploadResponseSnackBar}
        </>
      }
    />
  );
}

export default BidPartnerListModal;
