import { useParams } from "react-router-dom";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateQuotationStorageData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import useGetSearchEstimatePayload from "./hooks/useGetSearchEstimatePayload";

import QuotationForm from "./QuotationForm";

function BidCreateQuotation() {
  const [sessionStorageQuotationInfo, _setSessionStorageQuotationInfo] =
    useSessionStorage<BidCreateQuotationStorageData | null>(
      "sessionStorageQuotationInfo"
    );

  const [sessionStorageShipmentCreateUserInfo, _setShipmentCreateUserData] =
    useSessionStorage<BidCreateUserData | null>(
      "sessionStorageShipmentCreateUserInfo"
    );

  const [
    sessionStorageShipmentCreateInfo,
    setSessionStorageShipmentCreateInfo,
  ] = useSessionStorage<BidCreateStorageData | null>(
    "sessionStorageShipmentCreateInfo"
  );

  const handleSessionStorageShipmentCreateInfoChange = (
    value: BidCreateStorageData
  ) => {
    setSessionStorageShipmentCreateInfo(value);
  };

  const { freightType }: { freightType: FreightType } = useParams();

  /** 해당 견적서의 생성 폼 데이터 */
  const bidCreateFormData =
    sessionStorageShipmentCreateInfo?.bidCreateData[
      sessionStorageShipmentCreateInfo.tabsDefaultValue
    ].bidCreateFormData;

  // TODO: 싱가폴 강제 의뢰생성 > 견적서 기능은 없는 상태 추가 가능성은 있음 이때는 countryTarget이 필요
  const { data: exchangeRateList, isFetching: isExchangeRateListFetching } =
    COMMON_QUERY.useGetExchangeRateList({
      all: true,
    });

  const { data: applyItems, isFetching: isApplyItemsFetching } =
    ADMIN_BID_QUERY.useGetAdminBidApplyItems({});

  const { data: linerList, isFetching: isLinerListFetching } =
    COMMON_QUERY.useGetLinerList();

  const { data: portList } = COMMON_QUERY.useGetPortList();

  const { isEstimateFetching, estimate } = useGetSearchEstimatePayload({
    sessionStorageQuotationInfo,
    bidCreateFormData,
    freightType,
  });

  if (
    !bidCreateFormData ||
    !sessionStorageShipmentCreateInfo ||
    !sessionStorageShipmentCreateUserInfo ||
    !sessionStorageQuotationInfo ||
    isEstimateFetching ||
    isExchangeRateListFetching ||
    isApplyItemsFetching ||
    isLinerListFetching
  ) {
    return (
      <Layout>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout>
      <QuotationForm
        bidCreateFormData={bidCreateFormData}
        exchangeRateList={exchangeRateList}
        applyItems={applyItems}
        linerList={linerList}
        estimate={estimate}
        portList={portList}
        isOceanticket={sessionStorageQuotationInfo.isOceanticket}
        sessionStorageQuotationInfo={sessionStorageQuotationInfo}
        sessionStorageShipmentCreateUserInfo={
          sessionStorageShipmentCreateUserInfo
        }
        handleSessionStorageShipmentCreateInfoChange={
          handleSessionStorageShipmentCreateInfoChange
        }
        sessionStorageShipmentCreateInfo={sessionStorageShipmentCreateInfo}
      />
    </Layout>
  );
}

export default withRequireAuth(BidCreateQuotation);
